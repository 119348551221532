import React, { useState } from "react";
import { Button, Col, Form, Row, Spinner, Table } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { useAuth } from "../context/AuthContext";
import ModalDialog from "../Common/ModalDialog";
import { useRequest } from "../hooks/useRequest";
import { Link } from "react-router-dom";

function TableLoadingView() {
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md="auto">
          <Spinner animation="grow" /> Loading...
        </Col>
      </Row>
    </Container>
  );
}

function AdminSeasonsPage() {
  console.log("SeasonsPage-init");
  const auth = useAuth();
  function SeasonsList({
    // refreshListToken,
    onCreateClick,
    onEditClick,
    onDeleteClick,
  }: {
    // refreshListToken: string;
    onCreateClick: () => void;
    onEditClick: (season: any) => void;
    onDeleteClick: (season: any) => void;
  }) {
    // const [seasons, setSeasons] = useState<any>([]);
    const { data, error } = useRequest<any[]>(
      "http://localhost:3001/seasons",
      auth.token,
    );

    // function fetchSeasons() {
    //   fetch("", {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: auth.token,
    //     },
    //   })
    //     .then((response) => {
    //       if (!response.ok) {
    //         throw new Error("Unexpected Server Response");
    //       }
    //       return response.json();
    //     })
    //     .then(
    //       (result) => {
    //         console.log(result);
    //         setSeasons(result);
    //       },
    //       // Note: it's important to handle errors here
    //       // instead of a catch() block so that we don't swallow
    //       // exceptions from actual bugs in components.
    //       (error) => {
    //         console.error(error);
    //       },
    //     );
    // }

    // console.log("SeasonsList:", refreshListToken);

    // useEffect(() => {
    //   console.log("SeasonList onLoad");
    //   return fetchSeasons();
    // }, []);

    return (
      <>
        <Container className="admin-content">
          <Row>
            <Col sm={8}></Col>
            <Col sm={4} className="action-bar justify-content-end">
              <Button onClick={() => onCreateClick()} variant="primary">
                New Season
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              {error && <p>There is an error.</p>}
              {!data && <TableLoadingView />}
              {data && data.length === 0 && (
                <p>Click `Create` to create your first season.</p>
              )}
              {data && data.length > 0 && (
                <Table striped bordered hover className="crud">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th></th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((season: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>{season.name}</td>
                          <td>
                            <Link to={`${season.id}`}>Manage</Link>
                          </td>
                          <td align="right">
                            <Button onClick={() => onEditClick(season)}>
                              Edit Name
                            </Button>
                            &nbsp;
                            <Button
                              variant="danger"
                              onClick={() => onDeleteClick(season)}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  interface SeasonForm extends HTMLFormElement {
    nameField: HTMLInputElement;
  }

  function SeasonsFormView({
    season,
    onCancelClick,
  }: {
    season?: any;
    onCancelClick: () => void;
  }) {
    const [errorMessage, setErrorMessage] = useState("");
    const handleSubmit: React.FormEventHandler<SeasonForm> = (event) => {
      event.preventDefault();
      const name = event.currentTarget.nameField.value;
      if (!name || name.length === 0) {
        setErrorMessage("Name is required");
        return;
      }

      const isEdit = season?.id || false;

      const url =
        "http://localhost:3001/seasons" + (isEdit ? `/${season.id}` : "");

      fetch(url, {
        method: isEdit ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: auth.token,
        },
        body: JSON.stringify({
          season: {
            name,
          },
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Unexpected Server Response");
          }
          return response.json();
        })
        .then(
          (result) => {
            console.log(result);
            onCancelClick();
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            console.error(error);
            setErrorMessage(error);
          },
        );
    };
    return (
      <>
        <h1>Season Form</h1>

        {errorMessage && (
          <div className="alert alert-warning" role="alert">
            {errorMessage}
          </div>
        )}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="nameField">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Name"
              defaultValue={season?.name}
            />
            <Form.Text className="text-muted">i.e. 2021-2022</Form.Text>
          </Form.Group>

          <Button onClick={() => onCancelClick()} variant="secondary">
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </>
    );
  }

  function showForm(season?: any) {
    setContent(
      <SeasonsFormView
        season={season}
        onCancelClick={showList}
      ></SeasonsFormView>,
    );
  }
  function showList() {
    // console.log(refreshToken);
    setContent(
      <SeasonsList
        // refreshListToken={refreshToken}
        onCreateClick={showForm}
        onEditClick={showForm}
        onDeleteClick={confirmDelete}
      ></SeasonsList>,
    );
  }

  function confirmDelete(season: any) {
    console.log("Are you sure you want to delete", season);
    setContent(
      <div
        className="modal show"
        style={{ display: "block", position: "initial" }}
      >
        <ModalDialog
          titleText="Delete Season Confirmation"
          bodyText={`Are you sure you want to delete this season? (${season.name})`}
          onCancelClick={() => {
            console.log("Delete canceled");
            showList();
          }}
          onSaveClick={() => {
            console.log("Delete the Season", season);

            fetch(`http://localhost:3001/seasons/${season.id}`, {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: auth.token,
              },
            }).then(
              (response) => {
                if (!response.ok) {
                  throw new Error("Unexpected Server Response");
                }
                //setConfirmationDialoag(<></>);
                //setRefresh(Date.now().toString());
                showList();
              },
              (error) => {
                console.error(error);
              },
            );
          }}
        ></ModalDialog>
      </div>,
    );
  }
  //   const [refreshToken, setRefresh] = useState(Date.now().toString());
  //   console.log("refreshToken", refreshToken);
  //const [confirmationDialoag, setConfirmationDialoag] = useState(<></>);
  const [content, setContent] = useState(
    <SeasonsList
      //   refreshListToken={refreshToken}
      onCreateClick={showForm}
      onEditClick={showForm}
      onDeleteClick={confirmDelete}
    ></SeasonsList>,
  );

  return <Container className="w-100 m-auto">{content}</Container>;
}

export default AdminSeasonsPage;
