import Container from "react-bootstrap/Container";
import { useParams } from "react-router-dom";

function AdminSeasonManagePage() {
  let params = useParams();
  return (
    <Container className="w-100 m-auto">
      <h1>Manage Season {params.seasonId}</h1>
    </Container>
  );
}

export default AdminSeasonManagePage;
