import React from "react";

interface AuthContextType {
  user: any;
  token: string;
  isAuthenticated: () => boolean;
  isAdmin: () => boolean;
  login: (
    email: string,
    password: string,
    callback: (success: Boolean, errorMessage?: string) => void,
  ) => void;
  logout: (callback: VoidFunction) => void;
}

let AuthContext = React.createContext<AuthContextType>(null!);

function useAuth() {
  return React.useContext(AuthContext);
}

export { AuthContext, useAuth };
