import Container from "react-bootstrap/Container";

function HomePage() {
  return (
    <Container className="w-100 m-auto">
      <h1>Home</h1>
    </Container>
  );
}

export default HomePage;
