import React, { LegacyRef, MouseEventHandler } from "react";
import { Container, Dropdown, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { useAuth } from "./context/AuthContext";

const NavProfileDropdownToggle = React.forwardRef(
  (
    {
      children,
      onClick,
    }: {
      children: any;
      onClick: MouseEventHandler<HTMLAnchorElement>;
    },
    ref: LegacyRef<any>,
  ) => (
    <a
      data-bs-toggle="dropdown"
      aria-expanded="false"
      className="d-block text-decoration-none dropdown-toggle"
      href="/"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ),
);

function NavProfileDropdown() {
  return (
    <Dropdown drop="start">
      <Dropdown.Toggle
        as={NavProfileDropdownToggle}
        className="profile-dropdown"
      >
        <img
          src="https://github.com/mdo.png"
          alt="mdo"
          width="32"
          height="32"
          className="rounded-circle"
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Link to="/profile" className="nav-link">
          <i className="bi-person-fill"></i>
          Profile
        </Link>
        <Link to="/settings" className="nav-link">
          <i className="bi-list-check"></i>
          Settings
        </Link>
        <Dropdown.Divider />
        <Link to="/logout" className="nav-link">
          <i className="bi bi-key-fill"></i>
          Logout
        </Link>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function AppNav() {
  let auth = useAuth();
  console.log(auth.user);
  const expand = "sm";
  return (
    <Navbar
      expand={expand}
      bg="dark"
      data-bs-theme="dark"
      className="bg-body-tertiary"
    >
      <Container fluid>
        <Navbar.Brand href="/">PODIUM</Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${expand}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
              Offcanvas
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <NavLink to="/standings" className="nav-link">
                <i className="bi-bar-chart-line-fill"></i>
                Standings
              </NavLink>
              <NavLink to="/teams" className="nav-link">
                <i className="bi-collection-fill"></i>
                Teams
              </NavLink>
              <NavLink to="/players" className="nav-link">
                <i className="bi-person-fill"></i>
                Players
              </NavLink>
              {auth.isAuthenticated() && auth.isAdmin() && (
                <NavLink to="/admin" className="nav-link">
                  <i className="bi-pencil-square"></i>
                  Admin
                </NavLink>
              )}
            </Nav>
            {auth.isAuthenticated() && (
              <NavProfileDropdown></NavProfileDropdown>
            )}
            {!auth.isAuthenticated() && (
              <NavLink className="btn btn-outline-light me-2" to="/login">
                Login
              </NavLink>
            )}
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default AppNav;
