import { Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";

function AdminNav() {
  return (
    <Navbar className="justify-content-center admin-nav">
      <Nav fill variant="underline" defaultActiveKey="/home">
        <Nav.Item>
          <NavLink to="/admin" end className="nav-link">
            League Settings
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/admin/seasons" className="nav-link">
            Manage Seasons
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/admin/players" className="nav-link">
            Manage Players
          </NavLink>
        </Nav.Item>
      </Nav>
    </Navbar>
  );
}

export default AdminNav;
