import Container from "react-bootstrap/Container";

function AdminPlayersPage() {
  return (
    <Container className="w-100 m-auto">
      <h1>Players</h1>
    </Container>
  );
}

export default AdminPlayersPage;
