import FloatingLabel from "react-bootstrap/FloatingLabel";
import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./Login.css";

function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
  event.preventDefault();

  let formData = new FormData(event.currentTarget);

  let email = formData.get("email") as string;
  let name = formData.get("name") as string;
  let password = formData.get("password") as string;

  fetch("http://localhost:3001/register", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      user: {
        email,
        name,
        password,
      },
    }),
  })
    .then((res) => res.json())
    .then(
      (result) => {
        console.log(result);
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        console.error(error);
      },
    );
}

function RegisterForm() {
  return (
    <Form className="form-login" onSubmit={handleSubmit}>
      <FloatingLabel controlId="nameLabel" label="Name" className="mb-3">
        <Form.Control
          name="name"
          type="name"
          placeholder="Enter your name"
        ></Form.Control>
      </FloatingLabel>
      <FloatingLabel
        controlId="emailLabel"
        label="Email Address"
        className="mb-3"
      >
        <Form.Control
          name="email"
          type="email"
          placeholder="Enter your email"
        ></Form.Control>
      </FloatingLabel>
      <FloatingLabel
        controlId="passwordLabel"
        label="Password"
        className="mb-3"
      >
        <Form.Control
          name="password"
          type="password"
          placeholder="Enter your password"
        ></Form.Control>
      </FloatingLabel>
      <Button variant="primary" type="submit">
        Login
      </Button>
    </Form>
  );
}

export default RegisterForm;
