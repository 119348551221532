import { Table } from "react-bootstrap";
import Container from "react-bootstrap/Container";

function StandingsPage() {
  return (
    <Container className="w-100 m-auto">
      <h1>Standings</h1>
      <Table striped responsive bordered>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>GP</th>
            <th>W</th>
            <th>L</th>
            <th>T</th>
            <th>Pts</th>
            <th>GF</th>
            <th>GA</th>
            <th>DIFF</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Red</td>
            <td>20</td>
            <td>10</td>
            <td>8</td>
            <td>2</td>
            <td>22</td>
            <td>104</td>
            <td>234</td>
            <td>-123</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Blue</td>
            <td>20</td>
            <td>10</td>
            <td>8</td>
            <td>2</td>
            <td>22</td>
            <td>104</td>
            <td>234</td>
            <td>-123</td>
          </tr>
          <tr>
            <td>3</td>
            <td>White</td>
            <td>20</td>
            <td>10</td>
            <td>8</td>
            <td>2</td>
            <td>22</td>
            <td>104</td>
            <td>234</td>
            <td>-123</td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
}

export default StandingsPage;
