import React from "react";
import { AuthContext } from "./AuthContext";
import useStateWithLocalStorage from "../hooks/useStateWithLocalStorage";

const podiumAuthProvider = {
  login(
    email: string,
    password: string,
    callback: (success: Boolean, data?: any) => void,
  ) {
    fetch("http://localhost:3001/login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        user: {
          email,
          password,
        },
      }),
    })
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const responseData = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error =
            (responseData && responseData.message) || response.status;
          return Promise.reject(error);
        }
        callback(true, {
          user: responseData.data.user,
          token: response.headers.get("Authorization"),
        });
      })
      .catch((error) => {
        console.error("An error occurred while logging in", error);
        callback(false, "Error message");
      });
  },
  logout(callback: VoidFunction) {
    // TODO: call api endpoint
    callback();
  },
};

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useStateWithLocalStorage<any>("user", {});
  const [token, setToken] = useStateWithLocalStorage<string>("token", "");

  let login = (
    email: string,
    password: string,
    callback: (success: Boolean, errorMessage?: string) => void,
  ) => {
    return podiumAuthProvider.login(email, password, (success, data?) => {
      if (success && typeof data !== "undefined") {
        setUser(data.user);
        setToken(data.token);
      } else {
        setUser({});
        setToken("");
      }
      callback(success, data);
    });
  };

  let logout = (callback: VoidFunction) => {
    return podiumAuthProvider.logout(() => {
      setUser({});
      setToken("");
      callback();
    });
  };

  let isAuthenticated = () => typeof token !== "undefined" && token.length > 0;
  let isAdmin = () => true;

  let value = { user, token, login, logout, isAuthenticated, isAdmin };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
