import FloatingLabel from "react-bootstrap/FloatingLabel";
import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import "./Login.css";
import { useAuth } from "../context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

function LoginForm() {
  let auth = useAuth();
  let navigate = useNavigate();
  let location = useLocation();
  let [errorMessage, setErrorMessage] = React.useState("");
  let [isLoading, setIsLoading] = React.useState(false);

  let from = location.state?.from?.pathname || "/";

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (isLoading) return;
    setIsLoading(true);

    let formData = new FormData(event.currentTarget);
    let username = formData.get("email") as string;
    let password = formData.get("password") as string;

    auth.login(username, password, (success, em?) => {
      if (success) {
        navigate(from, { replace: true });
      } else if (typeof em !== "undefined") {
        setErrorMessage(em);
      }
      setIsLoading(false);
    });
  }
  return (
    <Form className="form-login" onSubmit={handleSubmit}>
      {errorMessage.length > 0 && (
        <Alert variant="danger">{errorMessage}</Alert>
      )}
      <FloatingLabel
        controlId="emailLabel"
        label="Email Address"
        className="mb-3"
      >
        <Form.Control
          name="email"
          type="email"
          placeholder="Enter your email"
        ></Form.Control>
      </FloatingLabel>
      <FloatingLabel
        controlId="passwordLabel"
        label="Password"
        className="mb-3"
      >
        <Form.Control
          name="password"
          type="password"
          placeholder="Enter your password"
        ></Form.Control>
      </FloatingLabel>
      <Button variant="primary" type="submit">
        {isLoading && <Spinner />}
        Login
      </Button>
    </Form>
  );
}

export default LoginForm;
