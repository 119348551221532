import "./App.css";
import {
  redirect,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import AppLayout from "./AppLayout";
import HomePage from "./LeaguePublic/HomePage";
import LoginPage from "./Auth/LoginPage";
import RequireAuth from "./Auth/RequireAuth";
import AdminHomePage from "./Admin/AdminHomePage";
import RegisterPage from "./Auth/RegisterPage";
import StandingsPage from "./LeaguePublic/Standings";
import PlayersPage from "./LeaguePublic/PlayersPage";
import TeamsPage from "./LeaguePublic/TeamsPage";
import AdminSeasonsPage from "./Admin/AdminSeasonsPage";
import AdminPlayersPage from "./Admin/AdminPlayersPage";
import AdminSeasonManagePage from "./Admin/AdminSeasonManagePage";
import { useAuth } from "./context/AuthContext";
import { Spinner } from "react-bootstrap";

function App() {
  let auth = useAuth();
  const router = createBrowserRouter([
    {
      id: "root",
      path: "/",
      loader() {
        // Our root route always provides the user, if logged in
        return { user: "" };
      },
      Component: AppLayout,
      children: [
        {
          index: true,
          Component: HomePage,
        },
        {
          path: "login",
          Component: LoginPage,
        },
        {
          path: "standings",
          Component: StandingsPage,
        },
        {
          path: "players",
          Component: PlayersPage,
        },
        {
          path: "teams",
          Component: TeamsPage,
        },
        {
          path: "register",
          Component: RegisterPage,
        },
        {
          path: "admin",
          element: (
            <RequireAuth>
              <AdminHomePage />
            </RequireAuth>
          ),
          children: [
            {
              path: "seasons",
              Component: AdminSeasonsPage,
            },
            {
              path: "seasons/:seasonId",
              Component: AdminSeasonManagePage,
            },
            {
              path: "players",
              Component: AdminPlayersPage,
            },
          ],
        },
      ],
    },
    {
      path: "/logout",
      element: <Spinner />,
      async loader() {
        // We signout in a "resource route" that we can hit from a fetcher.Form
        const logout = async () => {
          await new Promise<void>((resolve) => {
            console.log("33", auth);
            auth.logout(() => {
              console.log("2");
              resolve();
            });
          });
        };
        console.log("11");
        await logout();
        console.log("12");
        return redirect("/");
      },
    },
  ]);

  return <RouterProvider router={router} />;

  //     {/* <Routes>
  //       <Route element={<AppLayout />}>
  //         <Route path="/" element={<HomePage />} />
  //         <Route path="/login" element={<LoginPage />} />
  //         <Route path="/standings" element={<StandingsPage />} />
  //         <Route path="/players" element={<PlayersPage />} />
  //         <Route path="/teams" element={<TeamsPage />} />
  //         <Route path="/register" element={<RegisterPage />} />
  //         <Route
  //           path="/admin"
  //           element={
  //             <RequireAuth>
  //               <AdminHomePage />
  //             </RequireAuth>
  //           }
  //         >
  //           <Route path="seasons" element={<AdminSeasonsPage />} />
  //           <Route
  //             path="seasons/:seasonId"
  //             element={<AdminSeasonManagePage />}
  //           />
  //           <Route path="players" element={<AdminPlayersPage />} />
  //         </Route>
  //         <Route
  //           path="/profile"
  //           element={
  //             <RequireAuth>
  //               <ProfilePage />
  //             </RequireAuth>
  //           }
  //         />
  //         <Route path="*" element={<p>404 NOT FOUND</p>} />
  //       </Route>
  //     </Routes> */}
  // );
}

export default App;
