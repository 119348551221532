import Container from "react-bootstrap/Container";

function TeamsPage() {
  return (
    <Container className="w-100 m-auto">
      <h1>Teams</h1>
    </Container>
  );
}

export default TeamsPage;
