import Container from "react-bootstrap/Container";
import RegisterForm from "./RegisterForm";

function RegisterPage() {
  return (
    <Container className=" w-100 m-auto">
      <h1 className="h2 mb-3 fw-normal">Register</h1>
      <RegisterForm></RegisterForm>
    </Container>
  );
}

export default RegisterPage;
