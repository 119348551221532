import Container from "react-bootstrap/Container";
import LoginForm from "./LoginForm";
import { Link } from "react-router-dom";

function LoginPage() {
  return (
    <Container className=" w-100 m-auto">
      <h1 className="h2 mb-3 fw-normal">Sign in</h1>
      <LoginForm></LoginForm>
      <p>
        No acccount? <Link to="/register">Create an account</Link>
      </p>
    </Container>
  );
}

export default LoginPage;
