import { Outlet } from "react-router-dom";
import AppNav from "./AppNav";

function AppLayout() {
  return (
    <>
      <AppNav />
      <main id="content">
        <Outlet />
      </main>
    </>
  );
}

export default AppLayout;
