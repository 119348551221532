import { Button, Modal } from "react-bootstrap";

function ModalDialog({
  bodyText,
  titleText,
  cancelText = "Cancel",
  okText = "OK",
  onCancelClick,
  onSaveClick,
}: {
  bodyText: string;
  titleText: string;
  cancelText?: string;
  okText?: string;
  onCancelClick: () => void;
  onSaveClick: () => void;
}) {
  return (
    <Modal.Dialog>
      <Modal.Header>
        <Modal.Title>{titleText}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{bodyText}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancelClick}>
          {cancelText}
        </Button>
        <Button variant="primary" onClick={onSaveClick}>
          {okText}
        </Button>
      </Modal.Footer>
    </Modal.Dialog>
  );
}

export default ModalDialog;
