import { Container, Stack } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import AdminNav from "./AdminNav";

import "./Admin.css";

function AdminHomePage() {
  return (
    <Stack>
      <AdminNav />
      <Container className="w-100 m-auto">
        <Outlet />
      </Container>
    </Stack>
  );
}

export default AdminHomePage;
